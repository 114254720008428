<template>
<div>
    <b-card title="Etes-vous sûr de vouloir supprimer ce compte?">
      
     <b-row>
        <b-button variant="relief-danger" @click="deleteUser">Oui</b-button>&nbsp;
        <b-button variant="relief-secondary">Non</b-button>
     </b-row>
    </b-card>
</div>
</template>

<script>
import { BButton,BFormInput, BRow, BCol, BFormGroup,BFormFile, BCard, BFormCheckbox, BFormTextarea} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {APIRequest, Auth} from '@simb2s/senseye-sdk'
export default {
  name: 'DeleteUserForm', 
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormFile,
    BCard,
    BFormCheckbox,
    BFormTextarea,
    vSelect
  },
  data: {
      user: "",
      loaded: false
  },
  async mounted()
  {
    this.user=await Auth.get_user(this.$route.params.id)
  },
  methods: {
      async deleteUser(){
          let $delete = await Auth.delete(this.user.id)
           if($delete && $delete.success){
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: 'L\'utilisateur a bien été supprimé !',
                          icon: 'CheckIcon',
                          variant: 'success',
                      },
                  })
                  this.$router.push({name:'users'})
                }
      }
  }
}
</script>